.TimerProgress {
  &.progress {
    height: 45px;
    border-radius: 0;
    position: relative;
    text-align: center;
    z-index: 99;

    .progress-bar {
      width: 100%;
      height: 100%;
      transition-delay: 300ms;
    }

    .progress-bar:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 1px;
      width: var(--border-timer);
      border-top: var(--yellow-bb) solid 2px;
    }
    
    p {
      font-size: 14px;
      margin-top: 12px;
    }
  }
}
