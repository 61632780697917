.ModalAcceptDebitBB {
  .MuiBox-root {
    padding: 1.5rem;
    border-radius: 20px;
    max-height: 85vh;
    overflow-y: auto;
    border: none;
    outline: none;
  }

  h4 {
    font-size: 0.9375rem;
  }

  h3 {
    font-size: 1.0625rem;
  }

  h5 {
    font-size: 0.8125rem;
    font-style: italic;
    font-weight: 600;
  }

  h3,
  h4,
  h5 {
    color: #4d4c4c;
    margin-bottom: 15px;
  }

  h3,
  h4 {
    text-indent: 30px;
  }

  .round {
    margin-bottom: 15px;

    span {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .AppButton {
    background-color: var(--blue-bb) !important;
    color: var(--yellow-bb) !important;
    width: 80%;
    height: 40px;
    margin: 0 auto !important;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .ModalAcceptDebitBB {
    .MuiBox-root {
      width: 25%;
    }
  }
}
