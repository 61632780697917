.AvailableBB {
  background-color: white;

  .wrapper {
    padding: 0 0;
    text-align: center;

    &.h-100 {
      display: flex;
      align-items: center;
      min-height: 100vh;
    }

    .line {
      padding-top: 10px;
    }

    .line:nth-of-type(even) {
      background-color: #eeeded;
    }

    .line:nth-of-type(odd) {
      background-color: white;
    }

    h3 {
      color: var(--blue-bb);
      text-align: left;
      font-weight: 200;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 15px;
      margin-top: 10px;
    }

    h4 {
      color: var(--blue-bb);
      text-align: center;
      font-weight: 200;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 15px;
    }

    .AppButton {
      background-color: var(--blue-bb) !important;
      color: var(--yellow-bb) !important;
      font-size: 14px !important;
      padding: 12px 35px !important;
      margin-top: 10px;
    }

    .react-multi-carousel-list {
      width: 100%;
      padding: 5px 0px 30px;
    }
  }
}
