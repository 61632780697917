:root {
  --primary: white;
  --secondary: #E60000;
  --input: white;
  --success: #4caf50;
  --green: #33af03;
  --white: #FFFFFF;
  --gray: #666666;
  --primary-opacity: rgba(230, 0, 0, 0.24);
  --progress-circle: #E60000;
  --gray-dark: #494848;
  --blue-bb: #0053A1;
  --yellow-bb: #FFE40C;
  --border-timer: 50%;
}
