.Loading {
  justify-content: space-around;
  background-color: white;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
  animation-duration: 0.2ms;
  justify-content: center;
  z-index: 9;
  padding: 0;
  position: absolute;
  min-height: auto;

  .LoadingBody {
    flex-direction: column;
    align-items: center;
    display: flex;

    [data-icon="spinner"] {
      color: var(--primary);
      font-size: 50px;
    }

    .Content {
      text-align: center;
      padding-top: 20px;
      max-width: 400px;
      font-size: 16px;
    }
  }

  .Content {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--gray);
  }
}
