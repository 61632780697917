.AppButton {
  padding: 15px 35px !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-style: inherit !important;
  margin-bottom: 15px !important;
  font-weight: 600 !important;

  &.btn-white {
    background-color: var(--white) !important;
    color: var(--gray-dark) !important;
  }

  &.btn-gray {
    background-color: var(--gray-dark) !important;
    color: var(--white) !important;
  }

  &.btn-green {
    background-color: var(--green) !important;
    color: var(--white) !important;
  }

  &.btn-link {
    background-color: transparent !important;
    color: #0043ad !important;
    box-shadow: none;
  }

  &.btn-inverse {
    background-color: var(--white) !important;
    color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
  }

  &.w-100 {
    width: 100%;
    padding: 15px !important;
  }

  &.Mui-disabled {
    opacity: 0.3;
  }

  .MuiCircularProgress-circle {
    color: var(--progress-circle) !important;
  }
}
