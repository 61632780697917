.ModalProofBB {
  .action {
    position: fixed;
    bottom: 60px;
    right: 15px;
    width: 100%;
    margin: 0;
    text-align: right;
    z-index: 9;

    .AppButton2 {
      background-color: #fff;
      color: var(--primary);
      border: 2px solid var(--primary);
      font-size: 16px;
      width: 40px !important;
      height: 40px !important;
      min-width: auto !important;
      padding: 6px;
      height: auto;
      border-radius: 50px;
      margin-right: 15px;

      box-shadow: 0 0 0 0 var(--blue-bb);
      animation: pulse-blue 2s infinite;
    }
  }

  .MuiBox-root {
    padding: 1.2rem 0.9rem;
    border-radius: 20px;
    max-height: 85vh;
    overflow-y: auto;
    border: none;
    outline: none;
    position: relative;

    ul {
      list-style: none;
      padding: 0;
      display: table;
      margin: 0 auto;

      li {
        font-size: 12px;
        text-align: justify;
        font-family: monospace;
      }
    }

    .AppButton {
      background-color: #fff !important;
      color: var(--blue-bb) !important;
      border: 2px solid var(--blue-bb) !important;
      font-size: 14px !important;
      width: 90% !important;
      padding: 10px 30px !important;
      height: auto;
      border-radius: 50px !important;
      margin: 0 !important;
    }
  }
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--blue-bb);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(230, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(230, 0, 0, 0);
  }
}

@media (min-width: 768px) {
  .ModalProofBB {
    .MuiBox-root {
      width: 25%;

      ul {
        li {
          font-size: 14px;
        }
      }
    }
  }
}
