.ConclusionBB {
  background-color: var(--primary);
  min-height: 100vh;

  .titledesc {
    color: var(--secondary);
    font-weight: 300;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .titlenew {
    color: var(--secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0;
  }

  .AppButton {
    margin-top: 20px;
  }

  .img-success {
    width: 120px;
  }
}
