.InputCurrencyDecimal {
    margin-bottom: 5px !important;

    .MuiFilledInput-root {
        width: calc(100% - 40px);
        padding: 0px 25px;
        margin: 0 auto;
    }
    
    input {
        padding-top: 12px;
        font-size: 22px;
    }
}