@import 'assets/_styles/variables';

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gray);
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none;
}

.MuiCircularProgress-circle {
  color: var(--progress-circle);
}

label {
  &.Mui-focused {
    color: var(--input) !important;
  }
}

.MuiFilledInput-underline:after {
  border-bottom-color: var(--input) !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}