.OfferBB {
  background: url("/assets/images/products/bb/01-mobile.jpg") #f5f3f3 no-repeat center 0;
  background-size: cover;
  padding: 5px 13%;
  height: 100vh;

  .MuiTypography-h3 {
    color: var(--gray-dark);
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  img {
    width: 100%;
  }

  .AppButton {
    font-size: 16px !important;
    font-weight: 400 !important;
    width: 100%;
    padding: 20px 30px !important;
    text-transform: none;
    line-height: 20px;
    border-radius: 20px !important;
    background-color: #0053A1 !important;
    -webkit-box-shadow: 5px 19px 13px -1px rgba(0, 0, 0, 0.32);
    box-shadow: 5px 19px 13px -1px rgba(0, 0, 0, 0.32);
    text-align: center;
  }
}
