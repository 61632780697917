.CardLineCreditBB {
  background-color: transparent;
  margin: 0 5px;

  .MuiCard-root {
    border-radius: 9px;
    margin: 0;
    padding-bottom: 5px;
    width: 97%;
    max-width: 600px;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.1);

    .MuiCardContent-root {
      &:last-child {
        padding-bottom: 20px;
      }
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 0;

      h4 {
        font-size: 14px;
        color: var(--blue-bb);
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .titleDest {
      font-size: 20px;
      color: var(--gray);
      font-weight: 500;
      text-align: center;
    }

    .fieldValueDest {
      line-height: 30px;
      font-size: 42px;
      font-weight: 700;
      color: var(--blue-bb);
      text-align: center;
    }

    .title {
      font-size: 11px;
      line-height: 11px;
      color: var(--gray);
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 0.4rem;
      text-align: center;
    }

    .fieldValue {
      font-size: 14px;
      line-height: 6px;
      font-weight: 700;
      color: var(--blue-bb);
      text-align: center;
    }

    .lineButton {
      .AppButton {
        width: 100% !important;
        padding: 8px 10px !important;
        font-size: 12px !important;
        line-height: 13px !important;
        background-color: var(--yellow-bb) !important;
        margin-top: 20px !important;
      }
    }

    a {
      text-align: center;
      font-size: 14px;
      color: var(--gray) !important;
      cursor: pointer;
    }
  }

  .buttonColor {
    color: var(--blue-bb) !important;
  }
}

@media (min-width: 768px) {
  .CardLineCreditBB {
  }
}
