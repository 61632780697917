.MasterPage {

  text-align: center;
  height: 100vh;
  padding: 10px 25px;

  .app-logo {
    width: 200px;
  }

  //.MuiCircularProgress-circle{
  //  color: #33af03;
  //}

}