.Login {
  text-align: center;
  height: 100vh;
  padding: 10px 25px;

  .app-logo {
    width: 200px;
  }

  h6 {
    color: var(--secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  h5 {
    color: var(--secondary);
    font-size: 18px;
  }

  p {
    color: var(--secondary);
    font-size: 22px;
    line-height: 24px;
  }

  label {
    margin: 0 auto;

    span {
      font-size: 12px;
      color: var(--secondary);
      line-height: 14px;
      text-align: left;
    }
  }
}
