.ModalSimulateBB {
  .css-16tk2in {
    width: 30%;
  }

  .css-94ukvg {
    padding: 25px 0 8px;
  }

  .InputCurrencyDecimal {
    width: 100%;

    .MuiFilledInput-root {
      width: calc(100% - 40px);
      padding: 0 0;
    }
  }

  .MuiBox-root {
    padding: 1.5rem;
    border-radius: 20px;
    max-height: 85vh;
    overflow-y: auto;
    border: none;
    outline: none;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0;

    h4 {
      font-size: 16px;
      color: var(--blue-bb);
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .titleDest {
    font-size: 22px;
    line-height: 26px;
    color: var(--gray);
    font-weight: 500;
    text-align: center;
    padding-top: 0px;
  }

  .fieldValueDest {
    line-height: 38px;
    font-size: 40px;
    font-weight: 700;
    color: var(--blue-bb);
    text-align: center;
  }

  .title {
    font-size: 12px;
    line-height: 12px;
    color: var(--gray);
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 0.4rem;
    text-align: center;
  }

  .fieldValue {
    font-size: 14px;
    line-height: 10px;
    font-weight: 700;
    color: var(--blue-bb);
    text-align: center;
  }

  .lineTitle {
    width: 50%;
    padding-bottom: 10px;
    margin-top: 20px;

    &.m-0 {
      margin: 0;
      padding: 0;
    }

    .halfInstallment {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;

      .installments {
        font-size: 35px;
        font-weight: 200;
        color: #302f2f;
      }

      .btnRound {
        background-color: rgb(209, 208, 208);
        border: 0;
        color: #727171;
        width: 25px;
        height: 25px;
        text-align: center;
        padding: 4px 6px;
        font-size: 24px;
        line-height: 0.7;
        border-radius: 15px;
      }
    }
  }

  .halfValue {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
    font-size: 12px;
    font-weight: 600;
    color: #727171;
  }

  .titleInput {
    font-size: 16px;
    line-height: 18px;
    color: var(--gray);
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }

  .MuiTypography-body2 {
    font-size: 12px;
    width: calc(100% - 40px);
    padding: 0px 25px;

    &.error {
      color: var(--primary);
      font-size: 11px;
      line-height: 14px;
      font-weight: 600;
      margin-top: 3px;
    }
  }

  .AppButton {
    width: 200px !important;
    height: 35px;
    line-height: 13px;
    padding: 8px 0px !important;
    font-size: 12px !important;
    color: var(--blue-bb) !important;
    background-color: var(--yellow-bb) !important;

    &.blueBB {
      color: #fff !important;
      background-color: var(--blue-bb) !important;
    }
  }
}

@media (max-width: 767px) {
  .ModalSimulateBB {
    .lineTitle {
      width: 100%;
    }

    .css-16tk2in {
      width: 80%;
    }

    .MuiBox-root {
      width: 80%;
    }
  }
}
