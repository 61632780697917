.DetailsProposalBB {
  .details {
    .titleDest {
      font-size: 20px;
      color: var(--gray);
      font-weight: 500;
      text-align: center;
    }

    .fieldValueDest {
      line-height: 30px;
      font-size: 42px;
      font-weight: 700;
      color: var(--blue-bb);
      text-align: center;
    }

    .title {
      font-size: 12px;
      line-height: 12px;
      color: var(--gray);
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 0.4rem;
      text-align: center;
    }

    .fieldValue {
      font-size: 13px;
      line-height: 6px;
      font-weight: 700;
      color: var(--blue-bb);
      text-align: center;
    }
  }
}

@media (min-width: 768px) {
  .DetailsProposalBB {
    .details {
      .fieldValue {
        font-size: 16px;
        line-height: 12px;
      }
    }
  }
}