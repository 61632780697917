.PreviewBB {
  background-color: #f6f2ef;
  min-height: 100vh;
  padding-bottom: 40px;

  .wrapper {
    padding: 0 20px;
    text-align: center;

    .MuiBox-root {
      padding: 1.2rem;
      border-radius: 5px;
      border: none;
      outline: none;
    }

    h3 {
      color: var(--blue-bb);
      text-align: left;
      font-weight: 200;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .dest {
      text-align: center;
      color: var(--blue-bb);
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
    }

    .AppButton {
      background-color: var(--yellow-bb) !important;
      color: var(--blue-bb) !important;
      width: auto;
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 50px;

      &.bgBlueBB {
        background-color: var(--blue-bb) !important;
        margin-right: 3px;
      }
    }
  }
}

@media (min-width: 768px) {
  .PreviewBB {
    .wrapper {
      h3 {
        text-align: center;
      }

      .MuiBox-root {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
}
